@function increment-by-5($i) {
    @return 0 + ($i * 5);
}

//popover width
@for $i from 0 through 400 {
    .popover-#{increment-by-5($i)} {
        .popover {
            max-width: #{increment-by-5($i)}px !important;
            width: #{increment-by-5($i)}px !important;
        }
    }
}
