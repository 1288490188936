.combat-attributes {
    border-radius: 4px;
    background-color: rgb(161, 161, 161);
    padding: 5px 10px;
    font-size: 15px;
    input {
        padding: 3px;
    }
}

.combat-message {
    @extend .double-border;
    border-color: rgb(255, 102, 0);
    padding: 5px 10px;
}

.combat-roll {
    @extend .combat-message;
    border-color: rgb(88, 235, 74);
}

.effect-combat-history-message {
    @extend .combat-message;
    border-color: rgb(107, 140, 146);
}

.roll-combat-history-message {
    @extend .combat-message;
    border-color: rgb(88, 235, 74);
}

.generic-combat-history-message {
    @extend .combat-message;
}

.death-combat-history-message {
    @extend .combat-message;
    border-color: rgb(235, 192, 74);
}
