@function increment-by-5($i) {
    @return 0 + ($i * 5);
}

//HEIGHT
@for $i from 0 through 400 {
    .height-#{increment-by-5($i)} {
        height: #{increment-by-5($i)}px;
    }
    .height-#{$i} {
        height: #{$i}px;
    }
}

.h-20-f {
    height: 20px !important;
}

.h-30 {
    height: 30px;
}
.h-30-f {
    height: 30px !important;
}

.h-60 {
    height: 60px;
}

.height-100 {
    height: 100px !important;
}

.height-40vh {
    height: 40vh;
}

.max-height-50vh {
    max-height: 50vh;
}
.max-height-dice {
    max-height: calc(90vh - 420px);
}

.max-height-combat-sim {
    max-height: calc(90vh - 100px);
    min-height: calc(max(500px, calc(80vh - 100px)));
}

.height-100vh {
    height: 100vh;
}

.full-height {
    height: 100% !important;
}

@for $margin from 1 through 200 {
    .max-height-#{increment-by-5($margin)} {
        max-height: #{increment-by-5($margin)}px;
    }
    .min-height-#{increment-by-5($margin)} {
        min-height: #{increment-by-5($margin)}px;
    }

    .min-height-#{increment-by-5($margin)}-f {
        min-height: #{increment-by-5($margin)}px !important;
    }
}

.h-fit-content {
    height: fit-content !important;
}

//Fit with header
.fit-with-header {
    height: $height-without-header;
}

.fit-with-header-and-commandbar {
    height: calc(#{$height-without-header} - #{$commandbar-height});
}

//WIDTH
.full-width {
    width: 100%;
}
.w-a {
    width: auto;
}

//min-width
@for $i from 0 through 300 {
    .min-width-#{increment-by-5($i)} {
        min-width: #{increment-by-5($i)}px !important;
    }
    .max-width-#{increment-by-5($i)} {
        max-width: #{increment-by-5($i)}px !important;
    }
}

//pixels
@for $i from 0 through 400 {
    .width-#{increment-by-5($i)} {
        width: #{increment-by-5($i)}px !important;
    }
    .width-#{$i} {
        width: #{$i}px !important;
    }
}

//percents
@for $i from 0 through 100 {
    .width-#{$i}-p {
        width: #{$i}#{'%'};
    }
}
