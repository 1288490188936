.col-form-label {
    color: $color-label;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: none;
}

.col-form-label-3 {
    color: $color-label;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: none;
}
//Input

.small-input {
    font-size: 0.8rem;
    height: auto;
    line-height: 1;
}

.medium-input {
    font-size: 2rem;
    height: auto;
}

.large-input {
    font-size: 3.5rem;
    height: auto;
}

.placeholder-highlight {
    ::placeholder {
        color: red;
    }
}

.input-error {
    border-color: #f19a9aed;
    border-width: 1px;
}

.required {
    border: 1px solid red;
}

.hidden-input {
    background-color: inherit;
    border-width: 1px;
    border-color: transparent;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    color: $color-value;
}

.hidden-input:disabled {
    background-color: inherit;
}

.hidden-input:focus {
    background-color: white;
    color: inherit;
    border-color: $basic-button-color;
    box-shadow: none !important;
}

label {
    font-weight: bolder;
    margin-bottom: 0px;
}
.form-label {
    margin-bottom: 0px;
}

.clean-input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
