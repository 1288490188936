.modal-xxl {
    min-width: 300px;
    max-width: calc(max(70vw, 300px));
    min-height: 300px;
    max-height: calc(max(100vh, 300px));
    width: fit-content;
    img {
        min-width: 300px;
        max-width: calc(max(70vw, 300px));
        min-height: 300px;
        max-height: calc(max(calc(100vh - 15px), 300px));
    }
}
