p {
    margin-bottom: 0px;
}

.notes {
    margin: 5px 0px 0px 0px;
    max-width: calc(max(30vw, 300px));
    display: flex;
    flex-direction: column;
    min-width: 400px;
    min-height: 400px;
    width: 100%;

    .card-body {
        overflow-y: auto;
        min-height: 100px;
    }

    .private-note {
        background: repeating-linear-gradient(45deg, #e39f9f29, #bc606029 20px);
    }
}

.max-notes-width {
    max-width: calc(max(30vw, 300px));
}

.popover-note-message {
    .popover {
        padding: 20px;
        height: calc(max(50vh, 500px));
        min-height: calc(max(50vh, 500px));
        width: calc(max(30vw, 300px));
        min-width: calc(max(30vw, 300px));
        .popover-inner {
            @extend .flex-column;
            height: 100%;
        }
    }
    .note-editor {
        .ql-editor {
            overflow: auto;
            height: calc(max(50vh, 500px) - 140px);
            min-height: calc(max(50vh, 500px) - 140px);
        }
    }
}
