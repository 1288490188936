@for $i from 10 through 50 {
    .font-#{$i} {
        font-size: #{$i}px;
    }
}

.ancient-font {
    font-family: 'Spirax';
}

.therian-font {
    font-family: 'Source Sans Pro';
}

.default-font {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
        'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
}

//text align
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-end {
    text-align: end;
}

.text-hor-middle {
    vertical-align: middle;
}

.text-hor-bottom {
    vertical-align: bottom;
}

//font family

.header-font {
    font-size: 14px;
}

.text-italics {
    font-style: italic;
}

.text-crossed {
    text-decoration: line-through;
}

//font weight
.text-bolder {
    font-weight: bolder;
}

.text-normal {
    font-weight: normal;
}

.text-slim {
    font-weight: 400;
}

.text-extra-slim {
    font-weight: 100;
}

//text color
.text-highlight {
    color: $basic-button-color !important;
    label {
        color: $basic-button-color !important;
    }
}

//text size
.font-size-16 {
    font-size: 16px;
}

.font-size-16-f {
    font-size: 16px !important;
}
