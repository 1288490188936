.page-header {
    @extend .flex-row;
    @extend .noselect;
    align-items: center;
    background-color: #343a40 !important;
}

.page-title {
    color: white;
    font-style: italic;
}

.settings-dropdown {
    padding: 0px;
}

.settings-dropdown-toggle {
    background-color: inherit;
    border: none;
}

.settings-dropdown-toggle:hover,
.settings-dropdown-toggle:active {
    background-color: inherit !important;
    color: #007bff !important;
}

.settings-box {
    padding: 10px 20px;
    min-width: 240px;
}

.settings-header {
    @extend .flex-row;
    padding: 5px 20px;
    align-items: center;
    background-color: #eaeaea;
}

.settings-header .nav-link {
    color: #828583;
}

.settings-header .nav-link:hover,
.settings-header .nav-link.active {
    color: #007bff;
}
