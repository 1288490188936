.ql-editor {
    font-family: 'Arial';
    font-size: 16px;
}

.ql-snow .ql-color-picker .ql-picker-options {
    width: 380px;
}

.ql-picker.ql-font {
    .ql-picker-item {
        font-size: 0;
        &:before {
            content: attr(data-value) !important;
            font-size: 14px;
        }
    }
}

.ql-picker.ql-font {
    .ql-active {
        &:before {
            content: attr(data-value) !important;
            font-size: 14px;
        }
    }
}

.ql-picker.ql-font .ql-picker-label[data-value='Spirax']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Spirax']::before {
    font-family: 'Spirax', cursive;
    content: 'Spirax' !important;
}

.ql-font-Spirax {
    font-family: 'Spirax';
}

.ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
    font-family: 'Arial', cursive;
    content: 'Arial' !important;
}

.ql-font-Arial {
    font-family: 'Arial';
}
