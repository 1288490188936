.library-left {
    width: 500px;
    min-width: 500px;
    padding: 20px;
    height: calc(100vh - 55px);
    overflow-y: auto;
}

.library-right {
    flex: 1;
    height: calc(100vh - 91.5px);
    min-width: 1000px;
    overflow-y: auto;
}

.library-list-categories {
    .list-group-item {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .active {
        background-color: rgb(139, 99, 46);
        border-color: rgb(139, 99, 46);
    }
    .library-list-entries {
        .list-group-item {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        .list-group-item:last-child {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        .active {
            background-color: rgb(201, 143, 66);
            border-color: rgb(201, 143, 66);
        }
    }
}

.library-notes {
    min-width: 400px;
    @extend .default-font;
    .card {
        height: calc(100vh - 220px);
        margin: 10px 10px 10px auto;
        max-width: calc(max(30vw, 300px));
        min-width: 50%;
        display: flex;
        flex-direction: column;
        min-height: 400px;
    }
    .card-body {
        flex: 1 1 auto;
        overflow-y: auto;
        min-height: 100px;
    }
    .card-header {
        flex: 0 0 auto;
        height: min-content;
    }
    .library-note-control {
        flex: 0 0 auto;
        height: min-content;
    }
    .card-footer {
        flex: 0 0 auto;
        min-height: 200px;
    }
}

.library-notes.separate {
    .card {
        height: calc(100vh - 80px);
    }
}
