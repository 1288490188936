.checkbox-mark {
    height: 20px;
    width: 20px;
    padding-right: 15px;
    margin-left: 15px;
    border: 1px solid #c4c4c4;
    border-radius: 0px;
    background-color: white;
    margin-bottom: 0px;
}

.checkbox-mark:hover,
.checkbox-highlighted:hover {
    background-color: #ccc;
}

.checkbox-input {
    display: none;
}

.checkbox-highlighted {
    cursor: pointer;
    height: 20px;
    width: 20px;
    border: 1px solid #7ea7c5;
    border-radius: 0px;
    background-color: white;
    margin-bottom: 0px;
}

.checkbox-blocked {
    background-color: inherit !important;
    border: none;
}

.checkbox-container,
.checkbox-blocked-container {
    word-break: break-all;
    display: flex;
}

.checkbox-container .checkbox-mark:after,
.checkbox-container .checkbox-highlighted:after {
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-blocked-container .checkbox-mark:after,
.checkbox-blocked-container .checkbox-highlighted:after {
    width: 10px;
    height: 14px;
    border: solid #0078d4;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-mark:after,
.checkbox-highlighted:after {
    content: '';
    position: relative;
    left: 5px;
    top: 1px;
    display: none;
}

.checkbox-mark-grey {
    background-color: $muted-gray !important;
}

.checkbox-blocked-container input:checked ~ .checkbox-mark:after,
.checkbox-container input:checked ~ .checkbox-highlighted:after,
.checkbox-container input:checked ~ .checkbox-mark:after,
.checkbox-container input:checked ~ .checkbox-highlighted:after {
    display: block;
}

.checkbox-blocked-container input:checked ~ .checkbox-mark,
.checkbox-container input:checked ~ .checkbox-highlighted,
.checkbox-container input:checked ~ .checkbox-mark,
.checkbox-container input:checked ~ .checkbox-highlighted {
    background-color: $basic-button-color;
    border-color: $basic-button-color;
}

//OLD CHECKBOX

.custom-table-checkbox {
    padding-top: 12px !important;
}
/* Customize the label (the container) */
.custom-container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    //  border: 0.95px solid #636363;
    border: 1px solid #eee;
    border-radius: 0px;
    background-color: white;
}

/* On mouse-over, add a grey background color */
.custom-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-container input:checked ~ .checkmark {
    background-color: $basic-button-color;
    border-color: $basic-button-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-check-input {
    position: relative;
}

.form-control-checkbox {
    display: block;
    width: 100%;
    padding: 0.375rem 0px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: none;
}
