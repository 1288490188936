//POSITION
.position-inherit {
    position: inherit;
}

.position-absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

//DISPLAY
.display-none {
    display: none;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
}

.row {
    display: flex !important;
    margin: 0px;
}

//FLEX

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.flex-row-wrapped {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flex {
    flex: 1 1;
}

.flex-2 {
    flex: 2 2;
}

.flex-3 {
    flex: 3 3;
}

.display-flex {
    display: flex !important;
}

//ALIGNMENT
.ver-align-middle {
    vertical-align: middle;
}

.ver-align-bottom {
    vertical-align: baseline !important;
}

.align-items-center {
    align-items: center;
}

.align-self-center {
    align-self: center;
}

.stick-to-bottom {
    position: sticky;
    bottom: 0px;
}

.center-right-absolute {
    position: absolute;
    right: 10px;
    top: 10px;
}
