.player-icons {
    position: relative;
    div {
        position: absolute;
        bottom: 0;
    }
}
.max-height {
    max-height: calc(100vh - 36.57px);
    height: calc(100vh - 36.57px);
    min-height: calc(100vh - 36.57px);
}

.max-height-game {
    max-height: calc(100vh - 86.57px);
    height: calc(100vh - 86.57px);
    min-height: calc(100vh - 86.57px);
}

.max-height-20 {
    max-height: calc(100vh - 56.57px);
    height: calc(100vh - 56.57px);
    min-height: calc(100vh - 56.57px);
}

.calendar {
    background-color: white;
    border: solid;
    border-width: 7px;
    height: 50px;
}

.game-bar {
    .command-bar {
        .commandbar-dropdown {
            :active {
                color: black;
            }
            padding: 0px;
            margin-left: 15px;
            .commandbar-dropdown-inner {
                padding: 0px;
                margin-top: 15px;
            }
            .commandbar-dropdown-inner-toggle {
                background-color: rgb(0, 147, 220);
                border-radius: 0px;
                border: none;
            }
            .commandbar-dropdown-inner-toggle:hover {
                background-color: rgb(16, 117, 167);
            }
            .commandbar-dropdown-inner-toggle:disabled {
                background-color: rgb(141, 141, 141);
            }
        }
        .commandbar-dropdown-toggle {
            background-color: rgb(10, 49, 247);
            border: none;
        }
    }
}

.game-area {
    @extend .flex-2, .m-l-10, .m-r-10;
    .game-content {
        @extend .double-border;
        border-color: rgb(8, 157, 157);
    }
}

.dice-poker-result {
    @extend .text-center;
    @extend .text-hor-middle;
    border: solid 2px;
    border-radius: 6px;
    font-size: 20px;
    height: 40px;
    margin-top: 10px;
    margin-right: 27px;
    padding: 5px 10px 0px 10px;
    font-weight: bolder;
}
