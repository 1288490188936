.charsheet {
    @extend .noselect;
    @extend .ancient-font;
    background-size: contain;
    color: white;
    padding: 10px 20px;
    min-width: 650px;
    width: 650px;
    min-height: 700px;
    height: 700px;

    .location {
        input {
            color: white;
            background-color: transparent;
            border: none;
            font-size: 1.2em;
            padding: 0px;
            margin: 0px 0px 0px 5px;
        }
    }

    .charsheet-race-icon {
        position: relative;
        top: 30px;
        left: 20px;
        width: 110px;
        height: 110px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .charsheet-race-title {
        position: relative;
        top: 15px;
        left: 25px;
        font-size: 4em;
        .kill-count {
            margin-top: -20px;
            font-size: 0.3em;
        }
    }
    .charsheet-occupation {
        position: relative;
        left: 0;
        top: 0;

        .charsheet-occupation-icon {
            position: relative;
            top: 10px;
            left: 17px;
            width: 88px;
            height: 125px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .charsheet-occupation-border {
            position: relative;
            top: -118px;
            left: 12px;
            width: 97px;
            height: 132px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .char-info {
        margin-left: 15px;
        margin-top: 17px;
        line-height: 22px;
        font-size: 1.2em;
        input {
            color: white;
            background-color: transparent;
            border: none;
            font-size: 0.9em;
            padding: 0px;
            margin: 0px 0px 0px 5px;
        }
        .occupation-title {
            font-size: 2em;
            margin-bottom: 35px;
        }
    }
    .edit-button {
        position: relative;
        top: -170px;
        left: 200px;
    }
    .character-level {
        position: relative;
        font-size: 1.4em;
        left: -70px;
        input {
            color: white;
            background-color: transparent;
            border: none;
            font-size: 0.9em;
            padding: 0px;
            margin: 0px 0px 0px 5px;
        }
    }

    .equipment {
        width: 300px;
        .magic-icon {
            position: relative;
            top: 15px;
            left: 126px;
        }
        .accessories {
            position: relative;
            top: 15px;
            left: 85.5px;
        }
        .helmet {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 0px;
            left: 109.5px;
        }
        .hands {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 5.5px;
            left: 64px;
        }
        .chest {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 5.5px;
            left: 69.3px;
        }
        .shield {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 5.5px;
            left: 74.6px;
        }
        .weapon {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 4px;
            left: 10.2px;
        }
        .boots {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 4px;
            left: 16px;
        }
        .rune-1 {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 1.8px;
            left: 13px;
        }
        .rune-2 {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 1.8px;
            left: 26px;
        }
        .rune-3 {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 1.8px;
            left: 39px;
        }
        .potion-l {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 2.3px;
            left: 0px;
        }
        .potion-r {
            @extend .flex-row;
            img {
                @extend .center-ver;
            }
            position: relative;
            top: 2.3px;
            left: 5px;
        }
    }
}

.charsheet-portrait {
    margin-left: 75px;
    img {
        max-width: 190px;
        max-height: 250px;
    }
}

.charsheet-dead {
    position: relative;
    top: -220px;
    left: 50px;
    img {
        max-width: 190px;
        max-height: 250px;
    }
}

.charsheet-description {
    width: 280px;
    margin-left: 30px;
    font-size: 1.1em;
    top: 0px;
    line-height: 17px;
}

.gold-value {
    position: relative;
    left: 40px;
    font-size: 1.5em;
    top: 10px;
    color: #d9bb3b;
}

.charsheet-attributes {
    margin-left: 25px;
    margin-top: 0px;
    line-height: 20px;
    font-size: 1.2em;
}

.gold-history {
    border: thick double grey;
    font-size: 15px;
}

.char-inventory {
    width: 100%;
    border: thick double grey;
    max-width: 650px;
    min-height: 200px;
    .inventory-table {
        @extend .overflow-x;
        height: calc(100% - 46.66px);
        //height: calc(100vh - calc(736.57px + 55.35px));
        min-height: calc(200px - 55.35px);
    }
}

.tabs {
    @extend .flex-row;
    .tab {
        @extend .clickable;
        color: grey;
        font-size: 17px;
        margin: 10px 5px;
        font-weight: bolder;
    }
    .tab.green {
        @extend .color-green;
    }
    .tab.red {
        @extend .color-red;
    }
    .tab.active {
        border-bottom: 1px solid #007bff;
        color: #007bff;
    }
    .tab-dropdown {
        .dropdown .btn {
            background-color: transparent;
            border: none;
            color: grey;
            font-size: 17px;
            font-weight: bolder;
            margin: 10px 5px !important;
            padding: 0px;
        }
        .dropdown-menu {
            border-radius: 0px;
            padding: 0px;
            font-size: 17px;
        }
        .dropdown-item {
            padding-top: 0px;
            padding-bottom: 0px;
            font-weight: bolder;
            color: grey;
        }
        .dropdown-item.active {
            color: #007bff;
            background-color: white;
        }
    }
    .tab-dropdown.active {
        .dropdown .btn {
            border-bottom: 1px solid #007bff;
            border-radius: 0px;
            color: #007bff;
        }
    }
}
