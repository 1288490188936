.card {
    margin: 20px;
}
.small-card {
    .card-header {
        padding: 2px 10px;
    }
    .card-footer {
        padding: 2px 10px;
    }
    .card-body {
        padding: 5px;
    }
}
