.no-border {
    border: 0px;
}

.double-border {
    border: thick double grey;
}

.focus-border {
    border: thick double rgb(25, 221, 255);
}

.underline-selected {
    border-bottom: 2px solid #007bff;
}

.border-green {
    border-color: rgb(54, 192, 20);
}
