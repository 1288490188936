.login-card {
    background-color: white;
    border-radius: 6;
    padding: 10px 20px;
    width: 550px;
    margin: auto;
}

.reg-button {
    padding: 1px 3px !important;
    position: absolute;
    right: 25px;
}

.login-card {
    flex-direction: column;
    margin: auto !important;
}

.login-bkg {
    display: flex;
    height: 100vh;
}
