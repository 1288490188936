//background
.bkg-transparent {
    background-color: transparent !important;
}

.bkg-white {
    background-color: white !important;
}

.bkg-half-white {
    background-color: rgba(255, 255, 255, 0.34) !important;
}

.bkg-paper {
    background-image: url('https://elungia.wolf-bridge.tech/images/bkg-paper.jpg');
}

//color

.color-grey {
    color: #c5c5c5;
    tr {
        color: #c5c5c5;
    }
}

.color-white {
    color: white;
    tr {
        color: white;
    }
}

.color-green {
    color: green;
}

.color-blue {
    color: #007bff;
}

.color-red {
    color: red;
}

.hover-color-red:hover {
    color: red;
}

.color-orange {
    color: orange;
}

.color-gold {
    color: #d9bb3b;
}

.color-link {
    color: $color-link;
}

.color-rainbow {
    margin-top: 0px;
    margin-bottom: 10px;
    background: linear-gradient(
        to right,
        #ef5350,
        #f48fb1,
        #7e57c2,
        #2196f3,
        #26c6da,
        #43a047,
        #f9a825,
        #ff5722
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
