.settings-left {
    width: 500px;
    padding: 20px;
    height: calc(100vh - 71.5px);
    overflow-y: scroll;
}

.settings-right {
    flex: 1;
    height: calc(100vh - 71.5px);
    overflow-y: auto;
}

.list-tooltip {
    padding: 0px 5px;
    margin-top: 1;
    margin-bottom: 1;
}

.dropdown-item:active {
    background-color: inherit;
}

.items-list-categories {
    .list-group-item {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-left-radius: 0px;
    }
    .items-list-entries {
        .list-group-item {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }
        .list-group-item:last-child {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        .active {
            background-color: #458cf8;
            border-color: #458cf8;
        }
    }
}
