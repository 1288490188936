html,
body {
    height: 100%;
    //overflow: hidden;
    background-image: url('https://cdn.discordapp.com/attachments/599678258245795850/1093960688772337695/image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
}
