.generic-image-container {
    margin-right: 0px;
    img {
        max-width: 100% !important;
        max-height: 100% !important;
        min-height: auto !important;
        min-width: auto !important;
    }
}

.generic-image-container.in-tooltip {
    margin-left: -8px;
    margin-bottom: -4px;
    img {
        margin: auto;
    }
}

.img-bottom-cont {
    position: relative;
    .img-bottom {
        position: absolute;
        bottom: 0;
    }
}
