.btn-link {
    text-decoration: none;
}

.btn-link.color-red:active,
.btn-link.color-red:hover,
.btn-link.color-red:focus {
    color: red;
}

.thin-buttons {
    .btn {
        padding: 3px 3px;
    }
}
